<script setup>
import useWarehouses from "../../hooks/useWarehouses";
import { ref, watch, onBeforeMount } from "vue";
import debounce from "lodash/debounce";

const { fetchMyWarehouses, warehouses, getCachedWarehouseFilters } = useWarehouses();

const warehouseIds = ref([]);

/**
 * callback : This callback refreshes the grid after the warehouse filter is applied.
 * parentFilter : The name of the filter used by the parent component where the warehouse filter resides.
 */
const props = defineProps({
  callBack: Function,
  parentFilter: String,
});

/**
 * Save the warehouse filters to the local storage.
 */
const saveFiltersToLocalStorage = async () => {
  let filters = JSON.parse(window.localStorage.getItem(props.parentFilter));
  if (!filters) filters = {};
  if (typeof warehouseIds.value != "undefined") filters.warehouseIds = warehouseIds.value;
  window.localStorage.setItem(props.parentFilter, JSON.stringify(filters));
};

onBeforeMount(async () => {
  await fetchMyWarehouses();
  warehouseIds.value = getCachedWarehouseFilters(props.parentFilter);
});

const debouncedWarehouseCallback = debounce(async () => {
  await saveFiltersToLocalStorage();
  props.callBack();
}, 800);

watch(warehouseIds, (newIds, oldIds) => {
  // Do not trigger run the logic on page refresh.
  if (oldIds == undefined || oldIds.length == 0) {
    return;
  }
  debouncedWarehouseCallback();
});
</script>

<template>
  <div class="d-flex justify-end mx-4 w-full mt-4 mb-2">
    <div class="d-flex flex-wrap">
      <v-chip-group class="mt-0 pt-0" v-model="warehouseIds" multiple column mandatory>
        <v-chip filter outlined :value="w.id" v-for="w in warehouses?.data" :key="w.id">
          {{ w.nickname }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>
